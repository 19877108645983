[data-tab-link-active="true"] {
    border-right-color: #404040;
    background-color: #f8f8f9;
    opacity: 1;
  }
  
  
  
  [data-tab-content-active] {
    display: none;
  }
  
  [data-tab-content-active="true"] {
    display: block;
  }
  
  [data-tab-content-active="false"] {
    display: none;
  }
  
  [data-nav-element="mover"] {
    height: 0;
    overflow: hidden;
  }
  
  [data-nav-element="overlay"] {
    opacity: 0;
    display: none;
  }
  
  [data-nav-element="mover"] {
    border-radius: 0px 0px 12px 12px;
    box-shadow: 13px 118px 33px 0px rgba(41, 41, 41, 0.00), 8px 76px 30px 0px rgba(41, 41, 41, 0.01), 5px 42px 26px 0px rgba(41, 41, 41, 0.02), 2px 19px 19px 0px rgba(41, 41, 41, 0.04), 1px 5px 10px 0px rgba(41, 41, 41, 0.05);
  }
  [data-dropdown-item]{
    width: 100%;
  }
  [data-dropdown-item="resources"]{
    width: 100%;
  }
  [data-dropdown-item="company"]{
    width: 100%;
  }
  
  [data-desktop-content] {
    display: none;
  }
  
  @media (max-width: 992px) {
    [data-desktop-content] {
        display: block ; 
    }
  }
  
  